import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { getBookTripForm } from 'app/sagas/selectors';
import { showPickupTimeChangedNotification } from 'app/actions/book-trip'
import { flightToPickupTimeFromAirport, flightToPickupTimeFromHotel } from 'app/utils/flight'
import {
  BOOK_TRIP_FORM,
  DEPARTING_FLIGHT_FIELD_NAME,
  DEPARTING_PICKUP_FIELD_NAME,
  RETURNING_FLIGHT_FIELD_NAME,
  RETURNING_PICKUP_FIELD_NAME,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
  DEPARTING_RELATIVE_PICKUP_FIELD_NAME,
  RETURNING_RELATIVE_PICKUP_FIELD_NAME
} from 'app/constants';


// Constants
const flightToRelativePickupFields = {
  [DEPARTING_FLIGHT_FIELD_NAME]: DEPARTING_RELATIVE_PICKUP_FIELD_NAME,
  [RETURNING_FLIGHT_FIELD_NAME]: RETURNING_RELATIVE_PICKUP_FIELD_NAME
};
const flightToPickupFields = {
  [DEPARTING_FLIGHT_FIELD_NAME]: DEPARTING_PICKUP_FIELD_NAME,
  [RETURNING_FLIGHT_FIELD_NAME]: RETURNING_PICKUP_FIELD_NAME
};
const flightToTripFields = {
  [DEPARTING_FLIGHT_FIELD_NAME]: DEPARTING_TRIP_FIELD_NAME,
  [RETURNING_FLIGHT_FIELD_NAME]: RETURN_TRIP_FIELD_NAME
};

export function* adjustPickupTimeByFlight({
  meta: { field: flightField },
  payload: flight
}) {
  if (!isFlightComplete(flight)) return;

  const bookTripForm = yield select(getBookTripForm);
  const trip = bookTripForm[flightToTripFields[flightField]];

  const currentPickupTime = getTripPickupTime(trip, flightField);
  const recommendedPickupTime = isTripFromAirport(trip)
    ? flightToPickupTimeFromAirport(flight)
    : flightToPickupTimeFromHotel(flight, trip.duration)

  const pickupTimeDifference = currentPickupTime
    ? currentPickupTime.diff(recommendedPickupTime, 'minutes')
    : 0

  yield put(change(BOOK_TRIP_FORM, flightToRelativePickupFields[flightField], recommendedPickupTime));

  if (shouldUpdatePickupTime(pickupTimeDifference, trip)) {
    yield put(change(BOOK_TRIP_FORM, flightToPickupFields[flightField], recommendedPickupTime));
    yield put(showPickupTimeChangedNotification({
      field: flightToPickupFields[flightField],
      originTime: currentPickupTime.format('LLL'),
      newTime: recommendedPickupTime.format('LLL'),
    }))
  }
}

function isFlightComplete(flightObject) {
  return flightObject && flightObject.flightDatetime && flightObject.origin;
}

function getTripPickupTime(trip, flightField) {
  return flightField === DEPARTING_FLIGHT_FIELD_NAME
    ? trip.pickupDatetime
    : trip.returnPickupDatetime;
}

function isTripFromAirport(trip) {
  const mainStep = trip.steps.find(x => x.main);
  return !!mainStep.from.location.iataCode;
}


function shouldUpdatePickupTime(diffMins, trip) {
  const isFromAirport = isTripFromAirport(trip);
  const fltTrip = !!trip?.fltSupport;

  if (
    (isFromAirport && !fltTrip && diffMins < -20) ||
    (isFromAirport && fltTrip && Math.abs(diffMins) > 15)
  ) {
    return true
  }

  return false
}

export default adjustPickupTimeByFlight;
