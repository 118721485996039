import React from 'react';
import { connect } from 'react-redux';
import { setLocale } from 'app/history'
import { Layout } from 'app/components/base/Layout';
import { SmartLink } from 'app/components/base/SmartLink'
import { Header } from '@mozioinc/transfer-widgets';
import * as appActions from 'app/actions/app';
import * as userActions from 'app/actions/user-session';
import _ from 'lodash';
import {
  getActiveLanguage,
  getActiveCurrency,
  getLocation
} from 'app/sagas/selectors';
import posthog from 'posthog-js';

import style from './style.css';

class Navigation extends React.Component {
  state = {
    open: false
  };

  handleToggleNavbar = () => {
    this.setState({ open: !this.state.open });
  }

  handleSelectCurrency = (currency) => {
    const { setCurrency } = this.props;

    setCurrency({ currency: currency.code, changedByUser: true });
  }

  handleClickLogin = () => {
    const { saveRedirectBackUrl, location } = this.props;
    posthog.capture('Sign In Button Clicked In Navbar');
    saveRedirectBackUrl(location);
  }

  render() {
    const {
      activeLanguage,
      activeCurrency,
      logout,
    } = this.props;

    const debouncedLogout = _.debounce(logout, 3000, {
      leading: true,
      trailing: false
    });

    return (
      <Layout align="center center" className={style.container}>
         <Header
          onLogin={this.handleClickLogin}
          activeLanguage={activeLanguage}
          activeCurrency={activeCurrency}
          onLocaleChange={setLocale}
          onCurrencyChange={this.handleSelectCurrency}
          renderLink={(props) => <SmartLink to={props.to}>{props.children}</SmartLink>}
          onLogout={debouncedLogout}
        />
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Navigation.propTypes = {
//   languages: PropTypes.object,

//   // State
//   user: PropTypes.object,
//   activeLanguage: PropTypes.string,
//   activeCurrency: PropTypes.string,
//   location: PropTypes.object,

//   // Actions
//   setCurrency: PropTypes.func.isRequired,
//   logout: PropTypes.func.isRequired,
//   saveRedirectBackUrl: PropTypes.func.isRequired
// };

const mapStateToProps = state => {
  const activeLanguage = getActiveLanguage(state);
  const activeCurrency = getActiveCurrency(state);

  posthog.register({
    displayed_currency: activeCurrency,
    displayed_language: activeLanguage,
  });
  return {
    activeLanguage,
    activeCurrency,
    location: getLocation(state)
  };
};

const mapDispatchToProps = {
  ...appActions,
  ...userActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);


